import React from "react";
import Body from "./Body";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { mainPageBody, mainPageContent } from "../../../utils/styles";

const UploadMarks = () => {
  return (
    <div className={mainPageBody}>
      <Sidebar />
      <div className={mainPageContent}>
        <Header />
        <Body />
      </div>
    </div>
  );
};

export default UploadMarks;
