import React from "react";
import { Routes, Route } from "react-router-dom";
import AddAdmin from "./components/admin/addAdmin/AddAdmin";
import AddDepartment from "./components/admin/addDepartment/AddDepartment";
import AddFaculty from "./components/admin/addFaculty/AddFaculty";
import AddStudent from "./components/admin/addStudent/AddStudent";
import AddSubject from "./components/admin/addSubject/AddSubject";
import AdminHome from "./components/admin/AdminHome";

import GetFaculty from "./components/admin/getFaculty/GetFaculty";
import GetStudent from "./components/admin/getStudent/GetStudent";
import GetSubject from "./components/admin/getSubject/GetSubject";
import AdminProfile from "./components/admin/profile/Profile";
import AdminFirstTimePassword from "./components/admin/profile/update/firstTimePassword/FirstTimePassword";
import AdminPassword from "./components/admin/profile/update/password/Password";

import AdminUpdate from "./components/admin/profile/update/Update";
import CreateTest from "./components/faculty/createTest/CreateTest";
import FacultyHome from "./components/faculty/FacultyHome";
import MarkAttendance from "./components/faculty/markAttendance/MarkAttendance";
import FacultyProfile from "./components/faculty/profile/Profile";
import FacultyFirstTimePassword from "./components/faculty/profile/update/firstTimePassword/FirstTimePassword";
import FacultyPassword from "./components/faculty/profile/update/password/Password";
import FacultyUpdate from "./components/faculty/profile/update/Update";
import AccountUpdate from "./components/account/profile/update/Update";
import UploadMarks from "./components/faculty/uploadMarks/UploadMarks";
import AdminLogin from "./components/login/adminLogin/AdminLogin";
import FacultyLogin from "./components/login/facultyLogin/FacultyLogin";
import Login from "./components/login/Login";

import StudentLogin from "./components/login/studentLogin/StudentLogin";
import StudentFirstTimePassword from "./components/student/profile/update/firstTimePassword/FirstTimePassword";
import StudentHome from "./components/student/StudentHome";
import StudentProfile from "./components/student/profile/Profile";
import StudentUpdate from "./components/student/profile/update/Update";
import StudentPassword from "./components/student/profile/update/password/Password";
import SubjectList from "./components/student/subjectList/SubjectList";
import TestResult from "./components/student/testResult/TestResult";
import Attendance from "./components/student/attendance/Attendance";
import DeleteAdmin from "./components/admin/deleteAdmin/DeleteAdmin";
import DeleteDepartment from "./components/admin/deleteDepartment/DeleteDepartment";
import DeleteFaculty from "./components/admin/deleteFaculty/DeleteFaculty";
import DeleteStudent from "./components/admin/deleteStudent/DeleteStudent";
import DeleteSubject from "./components/admin/deleteSubject/DeleteSubject";
import CreateNotice from "./components/admin/createNotice/CreateNotice";

import AccountLogin from "./components/login/accountLogin/AccountLogin";
import AccountHome from "./components/account/AccountHome";
import AccountFirstTimePassword from "./components/account/profile/update/firstTimePassword/FirstTimePassword";
import GetAccount from "./components/admin/getAccount/GetAccount";
import AddAccount from "./components/admin/addAccount/AddAccount";
import DeleteAccount from "./components/admin/deleteAccount/DeleteAccount";
import AccountProfile from "./components/account/profile/Profile";
import CreateFeeReq from "./components/account/createFeeReq/CreateFeeReq";
import FeeReq from "./components/student/feereq/FeeReq";
import Inbox from "./components/account/inbox/Inbox";
import Completed from "./components/account/completed/Completed";
import Pending from "./components/account/pending/Pending";
import Partial from "./components/account/partial/Partial";

import ProtectedRouteAdmin from "./routes/admin/ProtectedRoute";
import ProtectedRouteAccount from "./routes/account/ProtectedRoute";
import ProtectedRouteStudent from "./routes/student/ProtectedRoute";
import ProtectedRouteFaculty from "./routes/faculty/ProtectedRoute";
import GetMarks from "./components/faculty/getMarks/getMarks";
import Leave from "./components/student/leave/Leave";
import FacultyLeave from "./components/faculty/leave/Leave";
import FacultyLeaves from "./components/admin/facultyleaves/FacultyLeaves";
import StudentLeaves from "./components/faculty/studentLeaves/StudentLeaves";

const App = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/login" element={<Login />} />

      {/* Admin  */}

      <Route path="/login/adminlogin" element={<AdminLogin />} />
      <Route
        path="/admin/home"
        element={
          <ProtectedRouteAdmin>
            <AdminHome />
          </ProtectedRouteAdmin>
        }
      />
      <Route
        path="/admin/profile"
        element={
          <ProtectedRouteAdmin>
            <AdminProfile />
          </ProtectedRouteAdmin>
        }
      />
      <Route
        path="/admin/update"
        element={
          <ProtectedRouteAdmin>
            <AdminUpdate />
          </ProtectedRouteAdmin>
        }
      />
      <Route
        path="/admin/update/password"
        element={
          <ProtectedRouteAdmin>
            <AdminPassword />
          </ProtectedRouteAdmin>
        }
      />
      <Route
        path="/admin/updatepassword"
        element={
          <ProtectedRouteAdmin>
            <AdminFirstTimePassword />
          </ProtectedRouteAdmin>
        }
      />
      <Route
        path="/admin/createnotice"
        element={
          <ProtectedRouteAdmin>
            <CreateNotice />
          </ProtectedRouteAdmin>
        }
      />
      {/* <Route
        path="/admin/addadmin"
        element={
          <ProtectedRouteAdmin>
            <AddAdmin />
          </ProtectedRouteAdmin>
        }
      />
      <Route
        path="/admin/deleteadmin"
        element={
          <ProtectedRouteAdmin>
            <DeleteAdmin />
          </ProtectedRouteAdmin>
        }
      /> */}
      <Route
        path="/admin/adddepartment"
        element={
          <ProtectedRouteAdmin>
            <AddDepartment />
          </ProtectedRouteAdmin>
        }
      />
      <Route
        path="/admin/deletedepartment"
        element={
          <ProtectedRouteAdmin>
            <DeleteDepartment />
          </ProtectedRouteAdmin>
        }
      />
      <Route
        path="/admin/addfaculty"
        element={
          <ProtectedRouteAdmin>
            <AddFaculty />
          </ProtectedRouteAdmin>
        }
      />
      <Route
        path="/admin/deletefaculty"
        element={
          <ProtectedRouteAdmin>
            <DeleteFaculty />
          </ProtectedRouteAdmin>
        }
      />
      <Route
        path="/admin/deletestudent"
        element={
          <ProtectedRouteAdmin>
            <DeleteStudent />
          </ProtectedRouteAdmin>
        }
      />
      <Route
        path="/admin/deletesubject"
        element={
          <ProtectedRouteAdmin>
            <DeleteSubject />
          </ProtectedRouteAdmin>
        }
      />
      
      <Route
        path="/admin/allfaculty"
        element={
          <ProtectedRouteAdmin>
            <GetFaculty />
          </ProtectedRouteAdmin>
        }
      />

      <Route
        path="/admin/facultyleaves"
        element={
          <ProtectedRouteAdmin>
            <FacultyLeaves />
          </ProtectedRouteAdmin>
        }
      />

      <Route
        path="/admin/addstudent"
        element={
          <ProtectedRouteAdmin>
            <AddStudent />
          </ProtectedRouteAdmin>
        }
      />
      <Route
        path="/admin/addsubject"
        element={
          <ProtectedRouteAdmin>
            <AddSubject />
          </ProtectedRouteAdmin>
        }
      />
      <Route
        path="/admin/allsubject"
        element={
          <ProtectedRouteAdmin>
            <GetSubject />
          </ProtectedRouteAdmin>
        }
      />
      <Route
        path="/admin/allstudent"
        element={
          <ProtectedRouteAdmin>
            <GetStudent />
          </ProtectedRouteAdmin>
        }
      />
      <Route
        path="/admin/allaccounts"
        element={
          <ProtectedRouteAdmin>
            <GetAccount />
          </ProtectedRouteAdmin>
        }
      />
      <Route
        path="/admin/addaccount"
        element={
          <ProtectedRouteAdmin>
            <AddAccount />
          </ProtectedRouteAdmin>
        }
      />
      <Route
        path="/admin/deleteaccount"
        element={
          <ProtectedRouteAdmin>
            <DeleteAccount />
          </ProtectedRouteAdmin>
        }
      />

      {/* Faculty  */}

      <Route path="/login/facultylogin" element={<FacultyLogin />} />
      <Route
        path="/faculty/home"
        element={
          <ProtectedRouteFaculty>
            <FacultyHome />
          </ProtectedRouteFaculty>
        }
      />
      <Route
        path="/faculty/password"
        element={
          <ProtectedRouteFaculty>
            <FacultyFirstTimePassword />
          </ProtectedRouteFaculty>
        }
      />
      <Route
        path="/faculty/profile"
        element={
          <ProtectedRouteFaculty>
            <FacultyProfile />
          </ProtectedRouteFaculty>
        }
      />
      <Route
        path="/faculty/update"
        element={
          <ProtectedRouteFaculty>
            <FacultyUpdate />
          </ProtectedRouteFaculty>
        }
      />
      <Route
        path="/faculty/update/password"
        element={
          <ProtectedRouteFaculty>
            <FacultyPassword />
          </ProtectedRouteFaculty>
        }
      />
      <Route
        path="/faculty/createtest"
        element={
          <ProtectedRouteFaculty>
            <CreateTest />
          </ProtectedRouteFaculty>
        }
      />
      <Route
        path="/faculty/uploadmarks"
        element={
          <ProtectedRouteFaculty>
            <UploadMarks />
          </ProtectedRouteFaculty>
        }
      />
      <Route
        path="/faculty/getmarks"
        element={
          <ProtectedRouteFaculty>
            <GetMarks />
          </ProtectedRouteFaculty>
        }
      />
      <Route
        path="/faculty/markattendance"
        element={
          <ProtectedRouteFaculty>
            <MarkAttendance />
          </ProtectedRouteFaculty>
        }
      />
      <Route
        path="/faculty/leave"
        element={
          <ProtectedRouteFaculty>
            <FacultyLeave />
          </ProtectedRouteFaculty>
        }
      />
      <Route
        path="/faculty/studentleaves"
        element={
          <ProtectedRouteFaculty>
            <StudentLeaves />
          </ProtectedRouteFaculty>
        }
      />

      {/* Student  */}

      <Route path="/login/studentlogin" element={<StudentLogin />} />
      <Route
        path="/student/home"
        element={
          <ProtectedRouteStudent>
            <StudentHome />
          </ProtectedRouteStudent>
        }
      />
      <Route
        path="/student/password"
        element={
          <ProtectedRouteStudent>
            <StudentFirstTimePassword />
          </ProtectedRouteStudent>
        }
      />
      <Route
        path="/student/profile"
        element={
          <ProtectedRouteStudent>
            <StudentProfile />
          </ProtectedRouteStudent>
        }
      />
      <Route
        path="/student/update"
        element={
          <ProtectedRouteStudent>
            <StudentUpdate />
          </ProtectedRouteStudent>
        }
      />
      <Route
        path="/student/update/password"
        element={
          <ProtectedRouteStudent>
            <StudentPassword />
          </ProtectedRouteStudent>
        }
      />
      <Route
        path="/student/subjectlist"
        element={
          <ProtectedRouteStudent>
            <SubjectList />
          </ProtectedRouteStudent>
        }
      />
      <Route
        path="/student/testresult"
        element={
          <ProtectedRouteStudent>
            <TestResult />
          </ProtectedRouteStudent>
        }
      />
      <Route
        path="/student/attendance"
        element={
          <ProtectedRouteStudent>
            <Attendance />
          </ProtectedRouteStudent>
        }
      />
      <Route
        path="/student/feereq"
        element={
          <ProtectedRouteStudent>
            <FeeReq />
          </ProtectedRouteStudent>
        }
      />
      <Route
        path="/student/leave"
        element={
          <ProtectedRouteStudent>
            <Leave />
          </ProtectedRouteStudent>
        }
      />

      {/* Account */}
      <Route path="/login/accountlogin" element={<AccountLogin />} />
      <Route
        path="/account/home"
        element={
          <ProtectedRouteAccount>
            <AccountHome />
          </ProtectedRouteAccount>
        }
      />
      <Route
        path="/account/update"
        element={
          <ProtectedRouteAccount>
            <AccountUpdate />
          </ProtectedRouteAccount>
        }
      />
      <Route
        path="/account/password"
        element={
          <ProtectedRouteAccount>
            <AccountFirstTimePassword />
          </ProtectedRouteAccount>
        }
      />
      <Route
        path="/account/profile"
        element={
          <ProtectedRouteAccount>
            <AccountProfile />
          </ProtectedRouteAccount>
        }
      />
      <Route
        path="/account/createfeereq"
        element={
          <ProtectedRouteAccount>
            <CreateFeeReq />
          </ProtectedRouteAccount>
        }
      />
      <Route
        path="/account/inbox"
        element={
          <ProtectedRouteAccount>
            <Inbox />
          </ProtectedRouteAccount>
        }
      />
      <Route
        path="/account/completed"
        element={
          <ProtectedRouteAccount>
            <Completed />
          </ProtectedRouteAccount>
        }
      />
      <Route
        path="/account/pending"
        element={
          <ProtectedRouteAccount>
            <Pending />
          </ProtectedRouteAccount>
        }
      />
      <Route
        path="/account/partial"
        element={
          <ProtectedRouteAccount>
            <Partial />
          </ProtectedRouteAccount>
        }
      />
    </Routes>
  );
};

export default App;
