import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createFacultyLeave, deleteFacultyLeave, getFacultyLeaves } from "../../../redux/api";
import DeleteIcon from "@mui/icons-material/Delete";

const Body = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [leavesData, setLeavesData] = useState([]);
  console.log("leaves data", leavesData);
  const [newLeave, setNewLeave] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});

  const handleInputChange = (e) => {
    setNewLeave({
      ...newLeave,
      [e.target.name]: e.target.value,
    });
  };

  const handleDeleteLeave = async (id) => {
    setLoading(true);
    try {
      const { data, status } = await deleteFacultyLeave(id);
      if (status === 200) {
        console.log("delete success", data.response);
        alert(`delete success`);
      }
    } catch (e) {
      console.log(e.response);
      alert(e.response.data.message);
    }
    setLoading(false);
  }

  const handleCreateNewLeave = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await createFacultyLeave({
        ...newLeave,
        id: user.result._id
      });
      const resp = data.response;
      console.log("new leave", resp);
    } catch (e) {
      console.log(e.response);
      alert(e.response.data.message);
    }

    setLoading(false);
  }

  useEffect(() => {
    if (error && error.message) {
      alert(error.message);
      setError({});
    }
  }, [error]);

  useEffect(async () => {
    const { data, status } = await getFacultyLeaves("all", user.result._id);
    if (status === 200) {
      setLeavesData(data.response);
    }
    console.log("data", data);
  }, [loading]);

  const store = useSelector((state) => state);

  useEffect(() => {
    if (store.deleteResp) {
      console.log("delResp", store.deleteResp);
    }
  }, [store.deleteResp]);

  useEffect(() => {
    if (Object.keys(store.errors).length !== 0) {
      setError(store.errors);
      setLoading(false);
    }
  }, [store.errors]);

  return (
    <div className="flex-[0.8] mt-3 mx-2">
      <div className="space-y-5">
        <div className="flex text-gray-400 items-center space-x-2">
          <h1>All Leaves</h1>
        </div>
        <div className="w-full flex flex-col md:flex-row md:justify-center md:space-x-6 p-6 h-fit overflow-auto">
          <div className={"p-3 w-fit"}>
            <table className="table overflow-x-scroll">
              <thead className="table-header-group border-2">
                <th className="border-r-2 px-1">Index</th>
                <th className="border-r-2 px-1">Start Date</th>
                <th className="border-r-2 px-1">End Date</th>
                <th className="border-r-2 px-1">Status</th>
                <th className="border-r-2 px-1">Reason</th>

                <th className="px-1">Delete</th>
              </thead>
              <tbody className="border-2">
                {
                  leavesData && leavesData.length > 0 &&
                  leavesData.map((leave, index) => (
                    <tr className="table-row border-b" key={index}>
                      <td className="border-r-2 px-1">{index + 1}</td>
                      <td className="border-r-2 px-1">{new Date(leave.start).toDateString()}</td>
                      <td className="border-r-2 px-1">{new Date(leave.end).toDateString()}</td>
                      <td className="border-r-2 px-1">{leave.status}</td>
                      <td className="border-r-2 px-1">{leave.reason}</td>
                      {leave.status !== "waiting" ?
                        <td>NA</td>
                        :
                        <td className="border-r-2 px-1 text-center" onClick={() => {
                          handleDeleteLeave(leave._id);
                        }}><DeleteIcon className="text-red-600" /></td>
                      }

                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>

          <form onSubmit={handleCreateNewLeave} className="flex flex-col items-start justify-start bg-gray-200 px-5 gap-5 py-5 rounded-xl w-fit">
            <div className="text-lg font-bold text-center">
              Add New Leave
            </div>
            <div className="flex gap-5">
              <div>
                <label>Start Date:</label>
                <br></br>
                <input
                  className="p-1 rounded-lg"
                  required
                  type="date"
                  name="start"
                  value={newLeave.start}
                  onChange={handleInputChange}
                ></input>
              </div>
            </div>
            <div className="flex gap-5">
              <div>
                <label>End Date:</label>
                <br></br>
                <input
                  className="p-1 rounded-lg"
                  required
                  type="date"
                  name="end"
                  value={newLeave.end}
                  onChange={handleInputChange}
                ></input>
              </div>
            </div>
            <div className="flex gap-5">
              <div>
                <label>Reason:</label>
                <br></br>
                <input
                  className="p-1 rounded-lg"
                  required
                  type="text"
                  name="reason"
                  value={newLeave.reason}
                  onChange={handleInputChange}
                ></input>
              </div>
            </div>
            <button
              type="submit"
              className="bg-white py-2 px-5 rounded-xl hover:bg-gray-800 hover:text-white"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Body;
