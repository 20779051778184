// // import React from "react";
// // import { Link } from "react-router-dom";
// // const Login = () => {
// //   return (
// //     <div
// //       className="md:h-screen w-screen backdrop-blur-md flex  justify-center"
// //       style={{
// //         backgroundImage: `url("https://images.unsplash.com/photo-1541339907198-e08756dedf3f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80")`,
// //         backgroundRepeat: "no-repeat",
// //         backgroundPosition: "center",
// //         backgroundSize: "cover",
// //       }}
// //     >
// //       <div className="flex flex-col items-center mt-10 space-y-32">
// //         <h1 className="text-3xl font-semibold bg-black text-white w-full text-center py-4 bg-opacity-75 rounded-2xl">
// //           Assam Institute Of Management
// //         </h1>
// //         <div className="grid grid-cols-3 gap-28">
// //           <div className="h-96 w-80 p-3 space-y-8 bg-white md:space-y-11 shadow-2xl flex flex-col justify-center items-center bg-transparent backdrop-blur-md bg-[#5a51d6] bg-opacity-40 rounded-xl">
// //             <h1 className="text-4xl font-extrabold">Faculty</h1>

// //             <Link
// //               type="button"
// //               to="/login/facultylogin"
// //               className="flex items-center justify-center bg-blue-500 h-10 w-32 text-lg rounded-lg text-white hover:scale-110 transition-all duration-200"
// //             >
// //               Login
// //             </Link>
// //           </div>
// //           <div className="h-96 w-80 space-y-8 bg-white md:space-y-11 shadow-2xl flex flex-col justify-center items-center bg-transparent backdrop-blur-md bg-[#d65158] bg-opacity-40 rounded-xl">
// //             <h1 className="text-4xl font-extrabold">Student</h1>
// //             <Link
// //               type="button"
// //               to="/login/studentlogin"
// //               className="flex items-center justify-center bg-blue-500 h-10 w-32 text-lg rounded-lg text-white hover:scale-110 transition-all duration-200"
// //             >
// //               Login
// //             </Link>
// //           </div>
// //           <div className="h-96 w-80 space-y-8 bg-white md:space-y-11 shadow-2xl flex flex-col justify-center items-center bg-transparent backdrop-blur-md bg-[#d65158] bg-opacity-40 rounded-xl">
// //             <h1 className="text-4xl font-extrabold">Account</h1>
// //             <Link
// //               type="button"
// //               to="/login/accountlogin"
// //               className="flex items-center justify-center bg-blue-500 h-10 w-32 text-lg rounded-lg text-white hover:scale-110 transition-all duration-200"
// //             >
// //               Login
// //             </Link>
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default Login;
// // import React from "react";
// // import { Link } from "react-router-dom";

// // const Login = () => {
// //   return (
// //     <div
// //       className="md:h-screen w-screen backdrop-blur-md flex justify-center"
// //       style={{
// //         backgroundImage: `url("https://images.unsplash.com/photo-1541339907198-e08756dedf3f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80")`,
// //         backgroundRepeat: "no-repeat",
// //         backgroundPosition: "center",
// //         backgroundSize: "cover",
// //       }}
// //     >
// //       <div className="flex flex-col items-center mt-10 space-y-32">
// //         <h1 className="text-3xl font-semibold bg-black text-white w-full text-center py-4 bg-opacity-75 rounded-2xl">
// //           Assam Institute Of Management
// //         </h1>
// //         <div className="grid grid-cols-3 gap-28">
// //           <div className="space-y-8 bg-white md:space-y-11 shadow-2xl flex flex-col justify-center items-center bg-transparent backdrop-blur-md bg-[#5a51d6] bg-opacity-40 rounded-xl">
// //             <h1 className="text-4xl font-extrabold">Faculty</h1>
// //             <Link
// //               type="button"
// //               to="/login/facultylogin"
// //               className="flex items-center justify-center bg-blue-500 h-10 w-32 text-lg rounded-lg text-white hover:scale-110 transition-all duration-200"
// //             >
// //               Login
// //             </Link>
// //           </div>
// //           <div className="space-y-8 bg-white md:space-y-11 shadow-2xl flex flex-col justify-center items-center bg-transparent backdrop-blur-md bg-[#d65158] bg-opacity-40 rounded-xl">
// //             <h1 className="text-4xl font-extrabold">Student</h1>
// //             <Link
// //               type="button"
// //               to="/login/studentlogin"
// //               className="flex items-center justify-center bg-blue-500 h-10 w-32 text-lg rounded-lg text-white hover:scale-110 transition-all duration-200"
// //             >
// //               Login
// //             </Link>
// //           </div>
// //           <div className="space-y-8 bg-white md:space-y-11 shadow-2xl flex flex-col justify-center items-center bg-transparent backdrop-blur-md bg-[#d65158] bg-opacity-40 rounded-xl">
// //             <h1 className="text-4xl font-extrabold">Accounts</h1>
// //             <Link
// //               type="button"
// //               to="/login/accountslogin"
// //               className="flex items-center justify-center bg-blue-500 h-10 w-32 text-lg rounded-lg text-white hover:scale-110 transition-all duration-200"
// //             >
// //               Login
// //             </Link>
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default Login;

// import React from "react";
// import { Link } from "react-router-dom";

// const Login = () => {
//   return (
//     <div
//       className="md:h-screen w-screen backdrop-blur-md flex flex-col items-center"
//       style={{
//         // backgroundImage: `url("https://images.unsplash.com/photo-1541339907198-e08756dedf3f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80")`,
//         backgroundImage: `url("/a2357ae8-1bd1-4e21-a8b0-6f47d8e17d96.JPG")`,
//         backgroundRepeat: "no-repeat",
//         backgroundPosition: "center",
//         backgroundSize: "cover",
//       }}
//     >
//       <div className="w-full flex items-center justify-between p-4">
//         <img
//           src="/aim-Logo-removebg-preview.png"
//           alt="Logo"
//           className="w-40 h-25 "
//         />
//         <h1 className="text-3xl font-semibold bg-black text-white py-4 px-8 bg-opacity-75 rounded-2xl">
//           Assam Institute Of Management
//         </h1>
//         <div className="w-16 h-16"></div>{" "}
//         {/* Empty div to balance the layout */}
//       </div>
//       <div className="flex flex-col items-center mt-10 space-y-32">
//         <div className="grid grid-cols-3 gap-28">
//           <div className="space-y-8 bg-white md:space-y-11 shadow-2xl flex flex-col justify-center items-center bg-transparent backdrop-blur-md bg-[#5a51d6] bg-opacity-40 rounded-xl">
//             <h1 className="text-4xl font-extrabold">Faculty</h1>
//             <Link
//               type="button"
//               to="/login/facultylogin"
//               className="flex items-center justify-center bg-blue-500 h-10 w-32 text-lg rounded-lg text-white hover:scale-110 transition-all duration-200"
//             >
//               Login
//             </Link>
//           </div>
//           <div className="space-y-8 bg-white md:space-y-11 shadow-2xl flex flex-col justify-center items-center bg-transparent backdrop-blur-md bg-[#d65158] bg-opacity-40 rounded-xl">
//             <h1 className="text-4xl font-extrabold">Student</h1>
//             <Link
//               type="button"
//               to="/login/studentlogin"
//               className="flex items-center justify-center bg-blue-500 h-10 w-32 text-lg rounded-lg text-white hover:scale-110 transition-all duration-200"
//             >
//               Login
//             </Link>
//           </div>
//           <div className="space-y-8 bg-white md:space-y-11 shadow-2xl flex flex-col justify-center items-center bg-transparent backdrop-blur-md bg-[#d65158] bg-opacity-40 rounded-xl">
//             <h1 className="text-4xl font-extrabold">Accounts</h1>
//             <Link
//               type="button"
//               to="/login/accountslogin"
//               className="flex items-center justify-center bg-blue-500 h-10 w-32 text-lg rounded-lg text-white hover:scale-110 transition-all duration-200"
//             >
//               Login
//             </Link>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Login;
import React from "react";
import { Link } from "react-router-dom";

const Login = () => {
  return (
    <div
      className="w-screen md:md:h-screen flex flex-col items-center"
      style={{
        backgroundImage: `url("/IMG_5140.jpg")`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="w-full flex flex-col md:flex-row items-center justify-between p-4">
        <img
          src="/aim-Logo-removebg-preview.png"
          alt="Logo"
          className="w-32 md:w-40 h-25"
        />
        <h1 className="mt-2 md:mt-0 text-lg md:text-3xl font-semibold bg-black text-white py-4 px-8 bg-opacity-75 rounded-2xl">
          Assam Institute Of Management
        </h1>
        <div className="w-16 h-16"></div>{" "}
        {/* Empty div to balance the layout */}
      </div>
      <div className="h-0 md:h-32"></div>
      <div className="flex flex-col items-center md:mt-10 md:my-10">
        <div className="grid md:grid-cols-3 gap-14 md:gap-28 mb-20">
          <div className="p-3 space-y-8 bg-white md:space-y-11 shadow-2xl flex flex-col justify-center items-center  bg-opacity-40 rounded-xl">
            <h1 className="text-4xl md:text-5xl font-extrabold text-black">Faculty</h1>
            <Link
              type="button"
              to="/login/facultylogin"
              className="flex items-center justify-center bg-orange-500 h-10 w-32 text-lg rounded-lg text-black hover:scale-110 transition-all duration-200"
            >
              <strong> Login </strong>
            </Link>
          </div>
          <div className="p-3 space-y-8 bg-white md:space-y-11 shadow-2xl flex flex-col justify-center items-center  bg-opacity-40 rounded-xl">
            <h1 className="text-4xl md:text-5xl font-extrabold text-black">Students</h1>
            <Link
              type="button"
              to="/login/studentlogin"
              className="flex items-center justify-center bg-orange-500 h-10 w-32 text-lg rounded-lg tblack hover:scale-110 transition-all duration-200"
            >
              <strong> Login </strong>
            </Link>
          </div>
          <div className="p-3 space-y-8 bg-white md:space-y-11 shadow-2xl flex flex-col justify-center items-center  bg-opacity-40 rounded-xl">
            <h1 className="text-4xl md:text-5xl font-extrabold text-black">Office</h1>
            <Link
              type="button"
              to="/login/accountlogin"
              className="flex items-center justify-center bg-orange-500 h-10 w-32 text-lg rounded-lg text-black hover:scale-110 transition-all duration-200"
            >
              <strong> Login </strong>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
