import axios from "axios";

const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_URL });
// const API = axios.create({ baseURL: "http://localhost:5000/" });

API.interceptors.request.use((req) => {
  if (localStorage.getItem("user")) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem("user")).token
      }`;
  }
  return req;
});

// Admin

export const adminSignIn = (formData) => API.post("/api/admin/login", formData);

export const adminUpdatePassword = (updatedPassword) =>
  API.post("/api/admin/updatepassword", updatedPassword);

export const getAllStudent = () => API.get("/api/admin/getallstudent");

export const getAllFaculty = () => API.get("/api/admin/getallfaculty");

export const getAllAdmin = () => API.get("/api/admin/getalladmin");

export const getAllDepartment = () => API.get("/api/admin/getalldepartment");
export const getAllSubject = () => API.get("/api/admin/getallsubject");

export const updateAdmin = (updatedAdmin) =>
  API.post("/api/admin/updateprofile", updatedAdmin);

export const addAdmin = (admin) => API.post("/api/admin/addadmin", admin);
export const createNotice = (notice) =>
  API.post("/api/admin/createnotice", notice);
export const deleteAdmin = (data) => API.post("/api/admin/deleteadmin", data);
export const deleteFaculty = (data) =>
  API.post("/api/admin/deletefaculty", data);
export const deleteAccount = (data) =>
  API.post("/api/admin/deleteaccount", data);
export const deleteStudent = (data) =>
  API.post("/api/admin/deletestudent", data);
export const deleteSubject = (data) =>
  API.post("/api/admin/deletesubject", data);
export const deleteDepartment = (data) =>
  API.post("/api/admin/deletedepartment", data);

export const getAdmin = (admin) => API.post("/api/admin/getadmin", admin);

export const addDepartment = (department) =>
  API.post("/api/admin/adddepartment", department);

export const addFaculty = (faculty) =>
  API.post("/api/admin/addfaculty", faculty);

export const getFaculty = (department) =>
  API.post("/api/admin/getfaculty", department);

export const getAccount = () => API.get("/api/admin/getaccount");

export const addAccount = (account) =>
  API.post("/api/admin/addaccount", account);

export const addSubject = (subject) =>
  API.post("/api/admin/addsubject", subject);
export const getSubject = (subject) =>
  API.post("/api/admin/getsubject", subject);

export const addStudent = (student) =>
  API.post("/api/admin/addstudent", student);

export const getStudent = (student) =>
  API.post("/api/admin/getstudent", student);
export const getNotice = (notice) => API.post("/api/admin/getnotice", notice);

// Faculty

export const facultySignIn = (formData) =>
  API.post("/api/faculty/login", formData);

export const facultyUpdatePassword = (updatedPassword) =>
  API.post("/api/faculty/updatepassword", updatedPassword);

export const updateFaculty = (updatedFaculty) =>
  API.post("/api/faculty/updateprofile", updatedFaculty);

export const createTest = (test) => API.post("/api/faculty/createtest", test);

export const getTest = (test) => API.post("/api/faculty/gettest", test);

export const getMarksStudent = (student) =>
  API.post("/api/faculty/getstudent", student);

export const uploadMarks = (data) => API.post("/api/faculty/uploadmarks", data);

export const getMarks = (data) => API.post("/api/faculty/getmarks", data);

export const markAttendance = (data) =>
  API.post("/api/faculty/updatedmarkattendance", data);

// Student

export const studentSignIn = (formData) =>
  API.post("/api/student/login", formData);

export const studentUpdatePassword = (updatedPassword) =>
  API.post("/api/student/updatepassword", updatedPassword);

export const updateStudent = (updatedStudent) =>
  API.post("/api/student/updateprofile", updatedStudent);

export const getTestResult = (testResult) =>
  API.post("/api/student/testresult", testResult);

export const getAttendance = (attendance) =>
  API.post("/api/student/attendance", attendance);

export const getUnpaidFeeRequests = (student) =>
  API.post("/api/student/getfee/unpaid", student);

export const getPaidFeeRequests = (student) =>
  API.post("/api/student/getpaidfee", student);

export const updateFeeRequests = (formData) =>
  API.post("/api/student/updatefee", formData);

// Account

export const accountSignIn = (formData) =>
  API.post("/api/account/login", formData);

export const accountUpdatePassword = (updatedPassword) =>
  API.post("/api/account/updatepassword", updatedPassword);

export const updateAccount = (updatedAccount) =>
  API.post("/api/account/updateaccount", updatedAccount);

export const createFeeRequest = (feeReq) =>
  API.post("/api/account/createfee", feeReq);

export const acceptFeeRequest = (feeReq, type) =>
  API.post(`/api/account/acceptfee/${type}`, feeReq);

export const modifyFeeRequest = (feeReq) =>
  API.post("/api/account/modifyfee", feeReq);

export const deleteFeeRequest = (feeReq) =>
  API.post("/api/account/deletefee", feeReq);

export const getFeeRequests = (mode) => API.get(`/api/account/getfee/${mode}`);

// export const getNewFeeRequests = () => API.get("/api/account/getmodfee");

// export const getPendingFeeRequests = () => API.get("/api/account/getpendingfee");

// export const getOldFeeRequests = () => API.post("/api/account/getoldfee");

export const getStudentLeaves = (mode, user) => API.get(`/api/student/getleave/${mode}/${user}`);
export const createStudentLeave = (formData) => API.post(`/api/student/createleave`, formData);
export const deleteStudentLeave = (id) => API.post(`/api/student/deleteleave`, { id });

export const getFacultyLeaves = (mode, user) => API.get(`/api/faculty/getleave/${mode}/${user}`);
export const createFacultyLeave = (formData) => API.post(`/api/faculty/createleave`, formData);
export const deleteFacultyLeave = (id) => API.post(`/api/faculty/deleteleave`, { id });
export const getFacultyLeavesForAdmin = (mode) => API.get(`/api/admin/getleave/faculty/${mode}`);
export const getStudentLeavesForFaculty = (mode) => API.get(`/api/faculty/getleaves/student/${mode}`);
export const updateFacultyLeave = (id, status, remarks, admin) =>
  API.post(`/api/admin/updatefacultyleave`, {
    id,
    status,
    remarks,
    admin
  }
);

export const updateStudentLeaveApi = (id, status, remarks, admin) =>
  API.post(`/api/admin/updatestudentleave`, {
    id,
    status,
    remarks,
    admin
  }
);