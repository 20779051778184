import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

export default function BarChart({ attendanceData }) {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    const myChartRef = chartRef.current.getContext("2d");

    const labels = attendanceData?.map((item) => item.subjectName);
    const data = attendanceData?.map((item) => parseFloat(item.percentage));

    // Function to generate dynamic colors
    const generateColors = (numColors) => {
      const colors = [];
      for (let i = 0; i < numColors; i++) {
        const r = Math.floor(Math.random() * 255);
        const g = Math.floor(Math.random() * 255);
        const b = Math.floor(Math.random() * 255);
        colors.push(`rgba(${r}, ${g}, ${b}, 0.2)`);
      }
      return colors;
    };

    const barColors = generateColors(data?.length);

    chartInstance.current = new Chart(myChartRef, {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Attendance Percentage",
            data: data,
            backgroundColor: barColors,
          },
        ],
      },
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [attendanceData]);

  return (
    <div>
      <canvas ref={chartRef} />
    </div>
  );
}
