import React, { useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import EngineeringIcon from "@mui/icons-material/Engineering";
import BoyIcon from "@mui/icons-material/Boy";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useSelector } from "react-redux";
import Notice from "../notices/Notice";
import ShowNotice from "../notices/ShowNotice";
import ReplyIcon from "@mui/icons-material/Reply";
import PieChart from "./PieChart";
import {pageBody, pageBodyContentContainer, profileContainer, profilePageBody} from "../../utils/styles";

const Body = () => {
  const [open, setOpen] = useState(false);
  const [openNotice, setOpenNotice] = useState({});
  const notices = useSelector((state) => state.admin.notices);
  const students = useSelector((state) => state.admin.allStudent);
  const faculties = useSelector((state) => state.admin.allFaculty);
  const admins = useSelector((state) => state.admin.allAdmin);
  const departments = useSelector((state) => state.admin.allDepartment);
  const subjects = useSelector((state) => state.admin.allSubject);
  // console.log(subjects);

  return (
    <div className={pageBody}>
      <div className="flex text-gray-400 items-center space-x-2">
        <HomeIcon />
        <h1>Dashboard</h1>
      </div>
      <div className={pageBodyContentContainer}>
        <div className="grid md:grid-cols-4 gap-4">
          <div className="bg-blue-500 max-w-72 p-4 rounded-xl shadow-lg text-white">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-4xl font-bold">{students?.length}</h2>
                <p>Total Students</p>
              </div>
              <BoyIcon sx={{ fontSize: 40 }} />
            </div>
          </div>
          <div className="bg-red-500 max-w-72 p-4 rounded-xl shadow-lg text-white">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-4xl font-bold">{faculties?.length}</h2>
                <p>Total Staffs</p>
              </div>
              <EngineeringIcon sx={{ fontSize: 40 }} />
            </div>
          </div>
          <div className="bg-yellow-500 max-w-72 p-4 rounded-xl shadow-lg text-white">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-4xl font-bold">{departments?.length}</h2>
                <p>Total Courses</p>
              </div>
              <MenuBookIcon sx={{ fontSize: 40 }} />
            </div>
          </div>
          <div className="bg-green-500 max-w-72 p-4 rounded-xl shadow-lg text-white">
            <div className="flex justify-between items-center">
              <div>
                <h2 className="text-4xl font-bold">{admins?.length}</h2>
                <p>Total Courses</p>
              </div>
              <SupervisorAccountIcon sx={{ fontSize: 40 }} />
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4">
          <div className="bg-white h-[17rem] md:w-1/2 rounded-xl shadow-lg flex flex-col pt-3 space-y-4">
            <div className="flex px-3">
              {open && (
                <ReplyIcon
                  onClick={() => setOpen(false)}
                  className="cursor-pointer"
                />
              )}
              <h1 className="font-bold text-xl w-full text-center">
                Notices
              </h1>
            </div>
            <div className="mx-5 mt-5 space-y-3 overflow-y-auto h-[12rem]">
              {!open ? (
                notices?.map((notice, idx) => (
                  <div
                    key={idx}
                    onClick={() => {
                      setOpen(true);
                      setOpenNotice(notice);
                    }}
                    className=""
                  >
                    <Notice idx={idx} notice={notice} notFor="" />
                  </div>
                ))
              ) : (
                <ShowNotice notice={openNotice} />
              )}
            </div>
          </div>
          <div className="bg-white h-[17rem] md:w-1/2 rounded-xl shadow-lg flex flex-col pt-3">
            <PieChart data={subjects} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
