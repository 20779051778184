import React, { useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import EngineeringIcon from "@mui/icons-material/Engineering";
import BoyIcon from "@mui/icons-material/Boy";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import ShowNotice from "../notices/ShowNotice";
import { useSelector } from "react-redux";
import ReplyIcon from "@mui/icons-material/Reply";
import Notice from "../notices/Notice";
import BarChart from "./BarChart"; // Import the BarChart component
import { pageBody, pageBodyContentContainer } from "../../utils/styles";

const Body = () => {
  const [open, setOpen] = useState(false);
  const [openNotice, setOpenNotice] = useState({});
  const notices = useSelector((state) => state.admin.notices);
  console.log(notices)
  const testResult = useSelector((state) => state.student.testResult);
  console.log(testResult)
  const attendance = useSelector((state) => state.student.attendance.result);
  console.log(attendance)
  const user = JSON.parse(localStorage.getItem("user"));
  const subjects = useSelector((state) => state.admin.subjects);
  const fees = useSelector((state) => state.student.unpaidreq);
  console.log(subjects)
  var totalAttendance = 0;

  attendance?.map((att) => (totalAttendance += att.attended));

  return (
    <div className={pageBody}>
      <div className="flex text-gray-400 items-center space-x-2">
        <HomeIcon />
        <h1>Dashboard</h1>
      </div>
      <div className={pageBodyContentContainer}>
        <div className="bg-white md:h-[8rem] pb-4 rounded-xl shadow-lg grid md:grid-cols-4 justify-between px-8 items-center md:space-x-4 space-y-4 md:space-y-0">
          <div className="flex items-center space-x-4 bg-blue-500 max-w-72 text-white rounded-lg p-4">
            <EngineeringIcon
              className="rounded-full bg-white text-blue-500 p-2"
              sx={{ fontSize: 40 }}
            />
            <div className="flex flex-col items-center">
              <h1 className="text-lg">Total Courses</h1>
              <h2 className="text-2xl font-bold">{subjects?.length}</h2>
            </div>
          </div>
          <div className="flex items-center space-x-4 bg-red-500 max-w-72 text-white rounded-lg p-4">
            <BoyIcon
              className="rounded-full bg-white text-red-500 p-2"
              sx={{ fontSize: 40 }}
            />
            <div className="flex flex-col items-center">
              <h1 className="text-lg">Tests</h1>
              <h2 className="text-2xl font-bold">{testResult?.length}</h2>
            </div>
          </div>
          <div className="flex items-center space-x-4 bg-yellow-500 max-w-72 text-white rounded-lg p-4">
            <SupervisorAccountIcon
              className="rounded-full bg-white text-yellow-500 p-2"
              sx={{ fontSize: 40 }}
            />
            <div className="flex flex-col items-center">
              <h1 className="text-lg">Attendance</h1>
              <h2 className="text-2xl font-bold">{totalAttendance}</h2>
            </div>
          </div>
          <div className="flex items-center space-x-4 bg-green-500 max-w-72 text-white rounded-lg p-4">
            <MenuBookIcon
              className="rounded-full bg-white text-green-500 p-2"
              sx={{ fontSize: 40 }}
            />
            <div className="flex flex-col items-center">
              <h1 className="text-lg">Semester</h1>
              <h2 className="text-2xl font-bold">{user.result.year}</h2>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
          <div className="bg-white h-[17rem] md:w-2/6 rounded-xl shadow-lg flex flex-col pt-3">
            <div className="flex px-3">
              {open && (
                <ReplyIcon
                  onClick={() => setOpen(false)}
                  className="cursor-pointer"
                />
              )}
              <h1 className="font-bold text-xl w-full text-center">
                Notices
              </h1>
            </div>
            <div className="mx-5 mt-5 space-y-3 overflow-y-auto h-[12rem]">
              {!open ? (
                notices?.map((notice, idx) => (
                  <div
                    onClick={() => {
                      setOpen(true);
                      setOpenNotice(notice);
                    }}
                    className=""
                    key={idx} // Add key prop for list items
                  >
                    <Notice idx={idx} notice={notice} notFor="faculty" />
                  </div>
                ))
              ) : (
                <ShowNotice notice={openNotice} />
              )}
            </div>
          </div>
          <div className="w-full md:w-4/6">
            <BarChart attendanceData={attendance} />{" "}
            {/* Render the BarChart component */}
          </div>
          <div className="flex flex-col gap-2 w-full md:w-3/6 overflow-auto">
              <strong>Pending Fee Requests</strong>
            {fees.map((fee, idx) => (
              <p className="px-1 bg-slate-50">{idx+1}. Amount: {fee.amount} Created On: {new Date(fee.createdDate).toDateString()} Remarks: {fee.remarks}</p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
