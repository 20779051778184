import {
  ACCOUNT_LOGIN,
  UPDATE_PASSWORD,
  UPDATE_ACCOUNT,
  CREATE_FEE_REQUEST,
  ACCEPT_FEE_REQUEST,
  MODIFY_FEE_REQUEST,
  DELETE_FEE_REQUEST,
  GET_PARTIAL_FEE_REQUESTS,
  GET_ALL_FEE_REQUESTS,
  GET_NEW_FEE_REQUESTS,
  GET_OLD_FEE_REQUESTS,
  GET_PENDING_FEE_REQUESTS,
  LOGOUT,
} from "../actionTypes";

const initialState = {
  authData: null,
  updatedPassword: false,
  updatedAccount: false,
  feeAdded: false,
  fees: [],
  pendingFees: [],
  partialFees: [],
  oldFees: [],
  newFees: [],
};

const facultyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACCOUNT_LOGIN:
      localStorage.setItem("user", JSON.stringify({ ...action?.data }));
      return { ...state, authData: action?.data };
    case LOGOUT:
      localStorage.clear();
      return { ...state, authData: null };
    case UPDATE_PASSWORD:
      return {
        ...state,
        updatedPassword: action.payload,
      };
    case UPDATE_ACCOUNT:
      localStorage.setItem("user", JSON.stringify({ ...action?.data }));
      return {
        ...state,
        authData: action?.data,
        updatedAccount: action.payload,
      };
    case CREATE_FEE_REQUEST:
      return {
        ...state,
        feeAdded: action.payload,
      };
    case GET_ALL_FEE_REQUESTS:
      return {
        ...state,
        fees: action.payload,
      };
    case GET_NEW_FEE_REQUESTS:
      return {
        ...state,
        newFees: action.payload,
      };
    case GET_OLD_FEE_REQUESTS:
      return {
        ...state,
        oldFees: action.payload,
      };
    case GET_PENDING_FEE_REQUESTS:
      return {
        ...state,
        pendingFees: action.payload,
      };
    case GET_PARTIAL_FEE_REQUESTS:
      return {
        ...state,
        partialFees: action.payload,
      };
    case ACCEPT_FEE_REQUEST:
      return {
        ...state,
        feeAdded: action.payload,
      };
    case MODIFY_FEE_REQUEST:
      return {
        ...state,
        feeAdded: action.payload,
      };

    default:
      return state;
  }
};

export default facultyReducer;
