import {
  SET_ERRORS,
  UPDATE_PASSWORD,
  TEST_RESULT,
  STUDENT_LOGIN,
  ATTENDANCE,
  UPDATE_STUDENT,
  GET_SUBJECT,
  GET_UNPAID_FEE_REQUESTS,
  GET_PAID_FEE_REQUESTS,
  UPDATE_FEE_REQUEST,
  GET_STUDENT_LEAVE,
  CREATE_STUDENT_LEAVE,
  DELETE_STUDENT_LEAVE,
  PAGE_REFRESH
} from "../actionTypes";
import * as api from "../api";

export const studentSignIn = (formData, navigate) => async (dispatch) => {
  try {
    const { data } = await api.studentSignIn(formData);
    dispatch({ type: STUDENT_LOGIN, data:data.response });
    if (data.response.result.passwordUpdated) navigate("/student/home");
    else navigate("/student/password");
  } catch (error) {
    dispatch({ type: SET_ERRORS, payload: error.response.data });
  }
};

export const studentUpdatePassword =
  (formData, navigate) => async (dispatch) => {
    try {
      const { data } = await api.studentUpdatePassword(formData);
      dispatch({ type: UPDATE_PASSWORD, payload: true });
      alert("Password Updated");
      navigate("/student/home");
    } catch (error) {
      dispatch({ type: SET_ERRORS, payload: error.response.data });
    }
  };

export const updateStudent = (formData, navigate) => async (dispatch) => {
  try {
    const { data } = await api.updateStudent(formData);
    dispatch({ type: UPDATE_STUDENT, payload: true, data: data.response });
    navigate("/student/profile")
  } catch (error) {
    dispatch({ type: SET_ERRORS, payload: error.response.data });
  }
};

export const getSubject = (department, year, section=undefined) => async (dispatch) => {
  try {
    const formData = {
      department,
      year,
    };

    if (section) {
      formData.section = section;
    }

    const { data } = await api.getSubject(formData);
    dispatch({ type: GET_SUBJECT, payload: data.response });
  } catch (error) {
    dispatch({ type: SET_ERRORS, payload: error.response.data });
  }
};

export const getTestResult =
  (department, year, section) => async (dispatch) => {
    try {
      const formData = {
        department,
        year,
        section,
      };
      const { data } = await api.getTestResult(formData);
      dispatch({ type: TEST_RESULT, payload: data.response });
    } catch (error) {
      dispatch({ type: SET_ERRORS, payload: error.response.data });
    }
  };

export const getAttendance =
  (department, year, section) => async (dispatch) => {
    try {
      const formData = {
        department,
        year,
        section,
      };
      const { data } = await api.getAttendance(formData);
      dispatch({ type: ATTENDANCE, payload: data.response });
    } catch (error) {
      dispatch({ type: SET_ERRORS, payload: error.response.data });
    }
  };

export const getUnpaidFeeRequests = (student) => async (dispatch) => {
  try {
    const formData = {
      student,
    };
    const { data } = await api.getUnpaidFeeRequests(formData);
    dispatch({ type: GET_UNPAID_FEE_REQUESTS, payload: data.response });
  } catch (error) {
    dispatch({ type: SET_ERRORS, payload: error.response.data });
  }
};
export const getPaidFeeRequests = (student) => async (dispatch) => {
  try {
    const formData = {
      student,
    };
    const { data } = await api.getPaidFeeRequests(formData);
    dispatch({ type: GET_PAID_FEE_REQUESTS, payload: data.response });
  } catch (error) {
    dispatch({ type: SET_ERRORS, payload: error.response.data });
  }
};

export const getStudentLeaves = (mode, studentId) => async (dispatch) => {
  try {
    const { data } = await api.getStudentLeaves(mode, studentId);
    dispatch({type: GET_STUDENT_LEAVE, payload: data.response});
  } catch (error) {
    dispatch({ type: SET_ERRORS, payload: error.response.data });
  }
}

export const createStudentLeave = (formData) => async (dispatch) => {
  try {
    const { data, status } = await api.createStudentLeave(formData);
    if (status === 200)
      dispatch({type: CREATE_STUDENT_LEAVE, payload: data.response});
    else if (status === 403)
      dispatch({type: SET_ERRORS, payload: data.response.message});
  } catch (error) {
    dispatch({type: SET_ERRORS, payload: error.response.data});
  }
}

export const deleteStudentLeave = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.deleteStudentLeave(id);
    console.log('delete leave resp', status, data);
    if (status === 200) dispatch({type: DELETE_STUDENT_LEAVE, payload: data.response.data});
    else dispatch({type: DELETE_STUDENT_LEAVE, payload: data.response});
    dispatch({type: PAGE_REFRESH, payload: "refresh"})
  } catch (error) {
    dispatch({type: SET_ERRORS, payload: error.response.data});
  }
}

export const updateFeeRequests =
  (fee, dop, remarks, feeItem) => async (dispatch) => {
    try {
      const formData = {
        fee,
        dop,
        remarks,
        feeItem,
      };
      const { data } = await api.updateFeeRequests(formData);
      dispatch({ type: UPDATE_FEE_REQUEST, payload: data.response });
    } catch (error) {
      dispatch({ type: SET_ERRORS, payload: error.response.data });
    }
  };
