import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

// Function to generate a random color
const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export default function PieChart({ data }) {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    // Destroy the previous chart instance if it exists
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    // Process the data to count the number of subjects per department
    const departmentCount = data.reduce((acc, curr) => {
      acc[curr.department] = (acc[curr.department] || 0) + 1;
      return acc;
    }, {});

    // Prepare the data for the chart
    const labels = Object.keys(departmentCount);
    const values = Object.values(departmentCount);

    // Generate dynamic colors
    const backgroundColors = labels.map(() => getRandomColor());

    const myChartRef = chartRef.current.getContext("2d");

    // Create a new chart instance
    chartInstance.current = new Chart(myChartRef, {
      type: "pie",
      data: {
        labels: labels,
        datasets: [
          {
            data: values,
            backgroundColor: backgroundColors,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    });

    // Clean up the chart instance on component unmount
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data]);

  return (
    <div className="h-full w-full">
      <canvas ref={chartRef} />
    </div>
  );
}
