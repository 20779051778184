import React, { useEffect, useState } from "react";
import Body from "./Body";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { getNewFeeRequests } from "../../../redux/actions/accountActions";
import { mainPageBody, mainPageContent } from "../../../utils/styles";

const Inbox = () => {
  const store = useSelector((state) => state);
  const [openWithHeader, setOpenWithHeader] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getNewFeeRequests("waiting"));
  }, [store.account.feeAdded, store.account.pendingFees, store.account.paidFees, openWithHeader]);
  return (
    <div className={mainPageBody}>
      <Sidebar />
      <div className={mainPageContent}>
        <Header />
        <Body openWithHeader={openWithHeader} setOpenWithHeader={setOpenWithHeader} />
      </div>
    </div>
  );
};

export default Inbox;
