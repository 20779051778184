// import React, { useEffect, useState } from "react";
// import { NavLink, useNavigate } from "react-router-dom";
// import HomeIcon from "@mui/icons-material/Home";
// import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
// import EngineeringIcon from "@mui/icons-material/Engineering";
// import AddIcon from "@mui/icons-material/Add";
// import { useDispatch } from "react-redux";
// import decode from "jwt-decode";
// const isNotActiveStyle =
//   "flex items-center px-5 gap-3 text-gray-500 hover:text-black transition-all duration-200 ease-in-out capitalize hover:bg-gray-200 py-2 my-1";
// const isActiveStyle =
//   "flex items-center px-5 gap-3 text-blue-600 transition-all duration-200 ease-in-out capitalize hover:bg-gray-200 py-2 my-1";

// const Sidebar = () => {
//   const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const logout = () => {
//     alert("OOPS! Your session expired. Please Login again");
//     dispatch({ type: "LOGOUT" });
//     navigate("/login/studentlogin");
//   };
//   useEffect(() => {
//     const token = user?.token;

//     if (token) {
//       const decodedToken = decode(token);
//       if (decodedToken.exp * 1000 < new Date().getTime()) logout();
//     }

//     setUser(JSON.parse(localStorage.getItem("faculty")));
//   }, [navigate]);
//   return (
//     <div className="flex-[0.2]">
//       <div className="space-y-8 overflow-y-scroll scrollbar-thin scrollbar-track-white scrollbar-thumb-gray-300 h-[33rem]">
//         <div className="">
//           <NavLink
//             to="/student/home"
//             className={({ isActive }) =>
//               isActive ? isActiveStyle : isNotActiveStyle
//             }
//           >
//             <HomeIcon className="" />
//             <h1 className="font-normal">Dashboard</h1>
//           </NavLink>
//           <NavLink
//             to="/student/profile"
//             className={({ isActive }) =>
//               isActive ? isActiveStyle : isNotActiveStyle
//             }
//           >
//             <AssignmentIndIcon className="" />
//             <h1 className="font-normal">Profile</h1>
//           </NavLink>
//         </div>
//         <div className="">
//           <NavLink
//             to="/student/testresult"
//             className={({ isActive }) =>
//               isActive ? isActiveStyle : isNotActiveStyle
//             }
//           >
//             <AddIcon className="" />
//             <h1 className="font-normal">Test results</h1>
//           </NavLink>
//           <NavLink
//             to="/student/attendance"
//             className={({ isActive }) =>
//               isActive ? isActiveStyle : isNotActiveStyle
//             }
//           >
//             <AddIcon className="" />
//             <h1 className="font-normal">Attendance</h1>
//           </NavLink>
//           <NavLink
//             to="/student/feereq"
//             className={({ isActive }) =>
//               isActive ? isActiveStyle : isNotActiveStyle
//             }
//           >
//             <AddIcon className="" />
//             <h1 className="font-normal">Fee Request</h1>
//           </NavLink>
//         </div>
//         <div className="">
//           <NavLink
//             to="/student/subjectlist"
//             className={({ isActive }) =>
//               isActive ? isActiveStyle : isNotActiveStyle
//             }
//           >
//             <EngineeringIcon className="" />
//             <h1 className="font-normal">Subject List</h1>
//           </NavLink>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Sidebar;

import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import EngineeringIcon from "@mui/icons-material/Engineering";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch } from "react-redux";
import decode from "jwt-decode";

const isNotActiveStyle =
  "flex items-center px-5 gap-3 text-gray-400 hover:text-black transition-all duration-200 ease-in-out capitalize hover:bg-gray-200 py-2 my-1";
const isActiveStyle =
  "flex items-center px-5 gap-3 text-white transition-all duration-200 ease-in-out capitalize hover:bg-gray-700 py-2 my-1";

const Sidebar = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(true);
  const logout = () => {
    alert("OOPS! Your session expired. Please Login again");
    dispatch({ type: "LOGOUT" });
    navigate("/");
  };
  useEffect(() => {
    const token = user?.token;

    if (token) {
      const decodedToken = decode(token);
      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }

    setUser(JSON.parse(localStorage.getItem("faculty")));
  }, [navigate]);
  return (
    <div className={toggle ? "w-12 md:w-auto flex-[1.0] bg-gray-800 min-h-screen h-full" : "w-auto flex-[1.0] bg-gray-800 min-h-screen h-full"}>
      <div className={"toggle absolute left-1 top-1 md:invisible"} onClick={() => {setToggle(prevState => !prevState)}}>
        {toggle ?
        <div className="w-fit rounded bg-white px-2 font-bold">&gt;&gt; Nav</div>
        :
        <div className="w-full rounded bg-white px-2 font-bold">&lt;&lt; Nav</div>
        }
      </div>
      <div className="space-y-8 overflow-y-scroll scrollbar-thin scrollbar-track-white scrollbar-thumb-gray-300 h-full">
        <div className="mt-6 md:mt-0">
          <NavLink
            to="/student/home"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <HomeIcon className="" />
            <h1 className="font-normal">Dashboard</h1>
          </NavLink>
          <NavLink
            to="/student/profile"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <AssignmentIndIcon className="" />
            <h1 className="font-normal">Profile</h1>
          </NavLink>
        </div>
        <div className="">
          <NavLink
            to="/student/testresult"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <AddIcon className="" />
            <h1 className="font-normal">Test results</h1>
          </NavLink>
          <NavLink
            to="/student/attendance"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <AddIcon className="" />
            <h1 className="font-normal">Attendance</h1>
          </NavLink>
          <NavLink
            to="/student/feereq"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <AddIcon className="" />
            <h1 className="font-normal">Fee Request</h1>
          </NavLink>
          <NavLink
            to="/student/leave"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <AddIcon className="" />
            <h1 className="font-normal">Leave Request</h1>
          </NavLink>
        </div>
        <div className="">
          <NavLink
            to="/student/subjectlist"
            className={({ isActive }) =>
              isActive ? isActiveStyle : isNotActiveStyle
            }
          >
            <EngineeringIcon className="" />
            <h1 className="font-normal">Subject List</h1>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
