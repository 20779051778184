import React from "react";
import * as classes from "../../../utils/styles";
const Data = ({ label, value }) => {
  return (
    <div className={classes.adminForm3}>
      <h1 className={classes.adminLabel}>{label} :</h1>
      <h2 className={classes.adminDataValue}>
        {value}
      </h2>
    </div>
  );
};

export default Data;
