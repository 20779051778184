import React, { useEffect, useState } from "react";
import SecurityUpdateIcon from "@mui/icons-material/SecurityUpdate";
import FileBase from "react-file-base64";
import { useDispatch, useSelector } from "react-redux";
import { updateAccount } from "../../../../redux/actions/accountActions";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { MenuItem, Select } from "@mui/material";
import Spinner from "../../../../utils/Spinner";
import { SET_ERRORS } from "../../../../redux/actionTypes";
import * as classes from "../../../../utils/styles";

const Body = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = useSelector((state) => state);
  const departments = useSelector((state) => state.admin.allDepartment);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [value, setValue] = useState({
    name: "",
    dob: "",
    email: user?.result?.email,
    department: "",
    contactNumber: "",
    avatar: "",
    designation: "",
  });

  useEffect(() => {
    if (Object.keys(store.errors).length !== 0) {
      setError(store.errors);
    }
  }, [store.errors]);

    const handleFileUpload = (file) => {
      const fileType = file.type.split("/")[0];
      const fileSize = file.size / 1024 / 1024; // Convert bytes to MB
      console.log(fileType);
      console.log(fileSize);
      if (fileType !== "image") {
        alert("Please upload a valid image file.");
        return;
      }

      if (fileSize > 0.5) {
        alert("File size exceeds 0.5MB.");
        return;
      }

      // If the file is valid, process it
      const reader = new FileReader();
      reader.onloadend = () => {
        setValue({ ...value, avatar: reader.result });
      };
      reader.readAsDataURL(file);
    };


  const handleSubmit = (e) => {
    e.preventDefault();
    setError({});
    setLoading(true);
    if (
      value.name === "" &&
      value.dob === "" &&
      value.department === "" &&
      value.contactNumber === "" &&
      value.avatar === "" &&
      value.designation === ""
    ) {
      alert("Enter atleast one value");
      setLoading(false);
    } else {
      dispatch(updateAccount(value, navigate));
      // alert("Kindly login again to see updates");
    }
  };

  useEffect(() => {
    if (store.errors || store.faculty.updatedFaculty) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [store.errors, store.faculty.updatedFaculty]);

  useEffect(() => {
    dispatch({ type: SET_ERRORS, payload: {} });
  }, []);

  return (
    <div className={classes.profilePageBody}>
      <div className={classes.profileHeaderContainer}>
        <div className="flex space-x-2 text-gray-400">
          <SecurityUpdateIcon />
          <h1>Update</h1>
        </div>

        <div
          onClick={() => navigate("/account/update/password")}
          className="flex space-x-2 cursor-pointer">
          <VisibilityOffIcon />
          <h1 className="font-bold">Password</h1>
        </div>
      </div>

      <form className={classes.profileContainer} onSubmit={handleSubmit}>
        <div className={classes.profileDataColumnsContainer}>
          <div className={classes.profileDataColumn}>
            <div className={classes.adminForm3}>
              <h1 className={classes.adminLabel}>Name :</h1>
              <input
                placeholder={user.result?.name}
                className={classes.adminInput}
                type="text"
                value={value.name}
                onChange={(e) =>
                  setValue({ ...value, name: e.target.value })
                }
              />
            </div>

            <div className={classes.adminForm3}>
              <h1 className={classes.adminLabel}>DOB :</h1>
              <input
                placeholder={user.result?.dob}
                className={classes.adminInput}
                type="text"
                value={value.dob}
                onChange={(e) =>
                  setValue({ ...value, dob: e.target.value })
                }
              />
            </div>

            <div className={classes.adminForm3}>
              <h1 className={classes.adminLabel}>Email :</h1>
              <input
                placeholder={user.result?.email}
                disabled
                className={classes.adminInput}
                type="text"
              />
            </div>
            <div className={classes.adminForm3}>
              <h1 className={classes.adminLabel}>Designation :</h1>
              <input
                placeholder={user.result?.designation}
                className={classes.adminInput}
                value={value.designation}
                onChange={(e) =>
                  setValue({ ...value, designation: e.target.value })
                }
                type="text"
              />
            </div>
          </div>

          <div className={classes.profileDataColumn}>
            <div className={classes.adminForm3}>
              <h1 className={classes.adminLabel}>Department :</h1>
              <Select
                displayEmpty
                sx={{ height: 36 }}
                inputProps={{ "aria-label": "Without label" }}
                value={value.department}
                onChange={(e) =>
                  setValue({ ...value, department: e.target.value })
                }>
                <MenuItem value="">None</MenuItem>
                {departments?.map((dp, idx) => (
                  <MenuItem key={idx} value={dp.department}>
                    {dp.department}
                  </MenuItem>
                ))}
              </Select>
            </div>

            <div className={classes.adminForm3}>
              <h1 className={classes.adminLabel}>Contact Number :</h1>
              <input
                placeholder={user.result?.contactNumber}
                className={classes.adminInput}
                type="text"
                value={value.contactNumber}
                onChange={(e) =>
                  setValue({ ...value, contactNumber: e.target.value })
                }
              />
            </div>

            <div className={classes.adminForm3}>
              <h1 className={classes.adminLabel}>Avatar :</h1>
              <FileBase
                type="file"
                multiple={false}
                onDone={({ base64, file }) => handleFileUpload(file)}
              />
            </div>
          </div>
        </div>

        <div className={classes.adminFormButton}>
          <button className={classes.adminFormSubmitButton} type="submit">
            Submit
          </button>

          <button
            onClick={() => navigate("/admin/profile")}
            className={classes.adminFormClearButton}
            type="button">
            Cancel
          </button>
        </div>

        <div className={classes.loadingAndError}>
          {loading && (
            <Spinner
              message="Updating"
              height={30}
              width={150}
              color="#111111"
              messageColor="blue"
            />
          )}
          {error.backendError && (
            <p className="text-red-500">{error.backendError}</p>
          )}
        </div>
      </form>
    </div>
  );
};

export default Body;
