import React, { useEffect, useState } from "react";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useDispatch, useSelector } from "react-redux";
import { updateFeeRequests } from "../../../redux/actions/studentActions";
import { MenuItem, Select } from "@mui/material";
import Spinner from "../../../utils/Spinner";
import { SET_ERRORS } from "../../../redux/actionTypes";
import * as classes from "../../../utils/styles";
import Card from "./Card";
import { useToaster, Message } from "rsuite";
import { useNavigate } from "react-router-dom";

import "../../../../node_modules/rsuite/useToaster/styles/index.css";

const Body = () => {
  const toaster = useToaster();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const fees = useSelector((state) => state.student.unpaidreq);
  const paidFees = useSelector((state) => state.student.paidreq);
  const [fee, setFee] = useState(fees[0]);
  // let fee = null;
  // if (fees.length > 0) {
  //   fee = fees[0];
  // }
  // const [feeList, setFee] = useState(fee);

  const message = (type) => (
    <Message showIcon type={type} closable>
      <strong>{type}!</strong> Request Updated.
    </Message>
  );

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    dop: new Date().toISOString().split("T")[0],
    remarks: "",
  });

  const handleFeeInputChange = (index, field, value) => {
    const updatedFeeItems = fee.feeItems.map((item, i) => {
      if (i === index) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setFee({ ...fee, feeItems: updatedFeeItems });
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateFeeRequest = (e) => {
    e.preventDefault();

    if (!formData.dop || !formData.remarks) {
      alert("Please fill all details");
      return;
    }
    dispatch(
      updateFeeRequests({
        fee: fee._id,
        dop: formData.dop,
        remarks: formData.remarks,
        feeItem: fee.feeItems,
      })
    );
    // window.location.reload();
    // toaster.push(message("success"), {
    //   placement: "topCenter",
    //   duration: 5000,
    // });
    alert("Fee Request Updated!");
    navigate("/student/home");
  };

  const store = useSelector((state) => state);

  useEffect(() => {
    if (Object.keys(store.errors).length !== 0) {
      setError(store.errors);
      setLoading(false);
    }
  }, [store.errors]);

  useEffect(() => {
    dispatch({ type: SET_ERRORS, payload: {} });
  }, []);
  console.log(fee);
  return (
    <div className="flex-[0.8] mt-3">
      <div className="space-y-5 ">
        <div className="flex flex-col gap-4 text-gray-400 items-center space-x-1">
          <div className="flex items-center gap-4">
            <MenuBookIcon />
            <h1>New Request</h1>
          </div>
          {fee != null && (
            <div className="w-full flex gap-10 justify-betweem">
              <div className="w-[300px] h-[200px] self-start bg-neutral-200 rounded-xl flex flex-col items-start p-3">
                <p className="text-black">Amount: {fee?.amount}</p>
                <p className="text-black text-sm font-light">
                  Remarks: {fee?.remarks}
                </p>
              </div>
              {fee?.status === "unpaid" && (
                <div>
                  <form
                    onSubmit={handleUpdateFeeRequest}
                    className="flex flex-col items-start justify-center gap-5"
                  >
                    <table>
                      <thead>
                        <tr>
                          <td>Fee Type</td>
                          <td>Fee Amount</td>
                          <td>Paid Amount</td>
                          <td>Transaction No.</td>
                        </tr>
                      </thead>
                      <tbody>
                        {fee.feeItems.map((el, index) => (
                          <tr key={index}>
                            <td>
                              <input
                                type="text"
                                disabled
                                value={el.itemName}
                              ></input>
                            </td>
                            <td>
                              <input
                                type="number"
                                disabled
                                value={el.itemAmount}
                              ></input>
                            </td>
                            <td>
                              <input
                                required
                                disabled // temporary disable
                                type="number"
                                // value={el.paidAmount || ""}
                                onChange={(e) =>
                                  handleFeeInputChange(
                                    index,
                                    "paidAmount",
                                    e.target.value
                                  )
                                }
                              ></input>
                            </td>
                            <td>
                              <input
                                required
                                disabled // temporary disable
                                type="text"
                                // value={el.txNo || ""}
                                onChange={(e) =>
                                  handleFeeInputChange(
                                    index,
                                    "txNo",
                                    e.target.value
                                  )
                                }
                              ></input>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="flex gap-5">
                      <div>
                        <label>Date of Payment</label>
                        <input
                          required
                          disabled // temporary disable
                          type="date"
                          name="dop"
                          value={formData.dop}
                          onChange={handleInputChange}
                        ></input>
                      </div>
                    </div>
                    <div>
                      <label>Remarks</label>
                      <textarea
                        required
                        disabled // temporary disable
                        name="remarks"
                        value={formData.remarks}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>
                    <button
                      type="submit"
                      className="bg-white py-2 px-5 rounded-xl"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              )}
              {fee?.status === "waiting" && (
                <div>
                  Office will report back as soon as possible.
                </div>
              )}
            </div>
          )}
          {fee == null && <div> No Fee Request</div>}
          <div className="flex items-center gap-4">
            <MenuBookIcon />
            <h1>Paid Fees</h1>
          </div>
          <div className="grid grid-cols-3 gap-5 h-[500px] overflow-auto">
            {paidFees.map((fee, idx) => (
              <Card key={idx} fee={fee} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Body;
