import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getAllStudent,
  getAllFaculty,
  getAllAdmin,
  getAllDepartment,
  getNotice,
  getAllSubject,
} from "../../redux/actions/adminActions";
import Body from "./Body";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { mainPageBody, mainPageContent } from "../../utils/styles";

const AdminHome = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllStudent());
    dispatch(getAllFaculty());
    dispatch(getAllAdmin());
    dispatch(getAllDepartment());
    dispatch(getNotice());
    dispatch(getAllSubject());
  }, [dispatch]);

  return (
    <div className={mainPageBody}>
      <Sidebar />
      <div className={mainPageContent}>
        <Header />
        <Body />
      </div>
    </div>
  );
};

export default AdminHome;

//sidebar ko uper ke div me
//h-full h-full (once per user)
//justify betwwenin .js file
