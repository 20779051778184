import React, { useEffect } from "react";
import Body from "./Body";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useDispatch, useSelector } from "react-redux";
import { getPartialFeeRequests } from "../../../redux/actions/accountActions";

const Completed = () => {
  const store = useSelector((state) => state);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPartialFeeRequests("partial"));
  }, [dispatch, store.account.feeAdded]);
  return (
    <div className="bg-[#d6d9e0] h-screen flex items-center justify-between">
      <Sidebar />
      <div className="flex flex-col  bg-[#f4f6fa] h-5/6 w-[85%] rounded-2xl shadow-2xl space-y-6 ">
        <Header />
        <div className="flex flex-[0.95]">
          <Body />
        </div>
      </div>
    </div>
  );
};

export default Completed;
