import {
  SET_ERRORS,
  ACCOUNT_LOGIN,
  UPDATE_PASSWORD,
  UPDATE_ACCOUNT,
  CREATE_FEE_REQUEST,
  ACCEPT_FEE_REQUEST,
  MODIFY_FEE_REQUEST,
  DELETE_FEE_REQUEST,
  GET_ALL_FEE_REQUESTS,
  GET_NEW_FEE_REQUESTS,
  GET_PENDING_FEE_REQUESTS,
  PARTIAL_ACCEPT_FEE_REQUEST,
  GET_PARTIAL_FEE_REQUESTS,
  GET_OLD_FEE_REQUESTS,
} from "../actionTypes";
import * as api from "../api";

export const accountSignIn = (formData, navigate) => async (dispatch) => {
  try {
    const { data } = await api.accountSignIn(formData);
    dispatch({ type: ACCOUNT_LOGIN, data: data.response });
    if (data.response.result.passwordUpdated) navigate("/account/home");
    else navigate("/account/password");
  } catch (error) {
    dispatch({ type: SET_ERRORS, payload: error });
  }
};

export const accountUpdatePassword =
  (formData, navigate) => async (dispatch) => {
    try {
      const { data } = await api.accountUpdatePassword(formData);
      dispatch({ type: UPDATE_PASSWORD, payload: true });
      navigate("/account/home");
    } catch (error) {
      dispatch({ type: SET_ERRORS, payload: error.response.data });
    }
  };

export const updateAccount = (formData, navigate) => async (dispatch) => {
  try {
    const { data } = await api.updateAccount(formData);
    dispatch({ type: UPDATE_ACCOUNT, payload: true, data: data.response });
    navigate("/account/profile");
  } catch (error) {
    dispatch({ type: SET_ERRORS, payload: error.response.data });
  }
};

export const createFeeRequest = (formData) => async (dispatch) => {
  console.log(formData);
  try {
    const { data } = await api.createFeeRequest(formData);
    
    dispatch({ type: CREATE_FEE_REQUEST, payload: true });
  } catch (error) {
    dispatch({ type: SET_ERRORS, payload: error.response.data });
  }
};
export const acceptFeeRequest = (formData) => async (dispatch) => {
  try {
    const { data, status } = await api.acceptFeeRequest(formData, "paid");
    console.log(status, data);
    
    dispatch({ type: ACCEPT_FEE_REQUEST, payload: true });
  } catch (error) {
    dispatch({ type: SET_ERRORS, payload: error.response.data });
  }
};
export const partialAcceptFeeRequest = (formData) => async (dispatch) => {
  try {
    const { data } = await api.acceptFeeRequest(formData, "partial");
    
    dispatch({ type: PARTIAL_ACCEPT_FEE_REQUEST, payload: true });
  } catch (error) {
    dispatch({ type: SET_ERRORS, payload: error.response.data });
  }
};
export const modifyFeeRequest = (formData) => async (dispatch) => {
  try {
    const { data } = await api.modifyFeeRequest(formData);
    console.log(data)
    dispatch({ type: MODIFY_FEE_REQUEST, payload: true });
  } catch (error) {
    dispatch({ type: SET_ERRORS, payload: error.response.data });
  }
};

//not using currently
export const deleteFeeRequest = (formData) => async (dispatch) => {
  try {
    const { data } = await api.deleteFeeRequest(formData);
    console.log(data)
    dispatch({ type: DELETE_FEE_REQUEST, payload: true });
  } catch (error) {
    dispatch({ type: SET_ERRORS, payload: error.response.data });
  }
};

export const getAllFeeRequests = (mode) => async (dispatch) => {
  try {
    const { data } = await api.getFeeRequests(mode);
    console.log(data)
    dispatch({ type: GET_ALL_FEE_REQUESTS, payload: data.response });
  } catch (error) {
    dispatch({ type: SET_ERRORS, payload: error.response.data });
  }
};
export const getNewFeeRequests = (mode) => async (dispatch) => {
  try {
    const {data} = await api.getFeeRequests(mode);
    console.log(data)
    dispatch({ type: GET_NEW_FEE_REQUESTS, payload: data.response });
  } catch (error) {
    console.log(error);
    dispatch({ type: SET_ERRORS, payload: error.response.data });
  }
};
export const getPendingFeeRequests = (mode) => async (dispatch) => {
  try {
    const { data } = await api.getFeeRequests(mode);
    console.log(data)
    dispatch({ type: GET_PENDING_FEE_REQUESTS, payload: data.response });
  } catch (error) {
    console.log(error);
    dispatch({ type: SET_ERRORS, payload: error });
  }
};
export const getOldFeeRequests = (mode) => async (dispatch) => {
  try {
    const { data } = await api.getFeeRequests(mode);
    console.log(data)
    dispatch({ type: GET_OLD_FEE_REQUESTS, payload: data.response });
  } catch (error) {
    dispatch({ type: SET_ERRORS, payload: error.response.data });
  }
};
export const getPartialFeeRequests = (mode) => async (dispatch) => {
  try {
    const { data } = await api.getFeeRequests(mode);
    console.log(data)
    dispatch({ type: GET_PARTIAL_FEE_REQUESTS, payload: data.response });
  } catch (error) {
    console.log(error)
    dispatch({ type: SET_ERRORS, payload: error });
  }
};

// export const uploadMark =
//   (marks, department, section, year, test) => async (dispatch) => {
//     try {
//       const formData = {
//         marks,
//         department,
//         section,
//         year,
//         test,
//       };
//       const { data } = await api.uploadMarks(formData);
//       dispatch({ type: MARKS_UPLOADED, payload: true });
//     } catch (error) {
//       dispatch({ type: SET_ERRORS, payload: error.response.data });
//     }
//   };

// export const markAttendance =
//   (checkedValue, subjectName, department, year, section) =>
//   async (dispatch) => {
//     try {
//       const formData = {
//         selectedStudents: checkedValue,
//         subjectName,
//         department,
//         year,
//         section,
//       };
//       const { data } = await api.markAttendance(formData);
//       dispatch({ type: ATTENDANCE_MARKED, payload: true });
//     } catch (error) {
//       dispatch({ type: SET_ERRORS, payload: error.response.data });
//     }
//   };
